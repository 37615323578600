import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import BounceLoader from "react-spinners/ClipLoader";
import RespondentFlowApi from "../services/respondentFlow.api";
import "./question.css";
import { updateStatus } from "../services/respondent.api";
import * as StatusConstants from "../constants/status.constants";
import { TextField } from "@mui/material";
import environment from "../environments/environment.json";
import LoggerService from "../services/logger.service";

const QuesCard = () => {
  const [Loader, setLoader] = useState(true);
  const [idx, setIdx] = useState(0);
  const [data, setData] = useState([]);
  const [obj, setObj] = useState({});
  let navigate = useNavigate();
  const [errorIndex, setErrorIndex] = useState("");
  const [match, setMatch] = useState(true);
  const [copyPasteData, setCopyPasteData] = useState(null);
  const [pageLoadTime, setPageLoadTime] = useState(null);
  const [textChangeTime, setTextChangeTime] = useState(null);
  const [subimtTime, setSubmitTime] = useState(null);

  // const [scReviewLib, setScReviewLib] = useState(null);

  useEffect(() => {
    getQuestions();
    // eslint-disable-next-line
  }, []);

  let getQuestions = () => {
    setLoader(true);

    let params = JSON.parse(sessionStorage.getItem("params"));

    if (params) {
      LoggerService.log(params.token, "", "Fetching survey screener questions", JSON.stringify(params));

      RespondentFlowApi.post("/screener/v1/questions", params)
        .then(async (response) => {
          if (response.result === "not_ok") {
            updateStatus(
              params.token,
              params.tokenId,
              StatusConstants.STATUS_SCREENER_START,
              StatusConstants.SUB_STATUS_SCREENER_DISPLAY_EXCEPTION
            );
            LoggerService.log(params.token, "", "Screener questions received", JSON.stringify(response));
            navigate(`/error?token=${params.token}`);
            return;
          }
          if (response.questionList.length === 0) {
            LoggerService.log(params.token, "", "No Screener questions received", JSON.stringify(response));
            let statusObj = { status: response.status };
            await handleStatusUpdate(statusObj);
          } else {
            let params = JSON.parse(sessionStorage.getItem("params"));

            let respData = response.questionList.map((value) => {
              if (value.type === 1 || value.type === 2) {
                value.answerId = [];
              } else {
                value.answerText = null;
              }
              return value;
            });
            updateStatus(
              params.token,
              params.tokenId,
              StatusConstants.STATUS_SCREENER_START,
              StatusConstants.SUB_STATUS_SCREENER_DISPLAY
            );
            setLoader(false);
            setData(respData);
          }
        })
        .catch((e) => {
          console.error(e);
          setLoader(false);
          navigate(`/error`);
        });
    } else {
      setLoader(false);
      navigate(`/error`);
    }
  };

  const encodeToBase64 = (str) => {
    // Convert string to UTF-8 bytes array
    const utf8Bytes = new TextEncoder().encode(str);

    // Convert bytes array to Base64 string
    const base64String = btoa(String.fromCharCode(...utf8Bytes));

    return base64String;
  };

  let handleStatusUpdate = async (resp) => {
    setLoader(true);
    let rparams = JSON.parse(sessionStorage.getItem("params"));
    let rObj = {
      traceId: rparams.traceId,
      token: rparams.token,
      tokenId: rparams.tokenId,
      status: resp.status,
      surveyUid: rparams.surveyUid,
      vendorUid: rparams.vendorUid,
      isMBD: rparams.isMBD ?? false,
      inviteId: rparams.inviteId ?? null,
    };

    rparams["status"] = resp.status;
    LoggerService.log(rparams.token, "", "Checking status on screener end", rparams);
    RespondentFlowApi.post("/surveyLanding/v1/update/status", rparams)
      .then(async (resp) => {
        if (resp.result === "not_ok") {
          navigate(`/error?token=${rparams.token}`);
          return;
        }
        if (resp.isInternalRedirect) {
          LoggerService.log(rparams.token, "", "New Survey received " + resp.surveyUid, JSON.stringify(resp));
          let obj = {};
          obj.traceId = resp.traceId;
          obj.paramList = resp.paramList;
          obj.sessionUid = resp.sessionUid;
          obj.surveyUid = resp.surveyUid;
          obj.vendorUid = resp.vendorUid;
          obj.token = resp.token;
          obj.tokenId = resp.tokenId;
          obj.userUid = resp.userUid;
          obj.userId = resp.userId;
          obj.isMBD = resp.isMBD;
          obj.inviteId = resp.inviteId;
          obj.mbdSurveyUrl = resp.mbdSurveyUrl;
          sessionStorage.removeItem("params");
          sessionStorage.setItem("params", JSON.stringify(obj));
          window.location.href = `/screener?token=${resp.token}`;
        } else {
          sessionStorage.removeItem("params");
          if (resp.redirectType === 3) {
            // await updateStatus(
            //   rObj.token,
            //   rObj.tokenId,
            //   StatusConstants.STATUS_SUPPLIER_REDIRECT_START,
            //   StatusConstants.SUB_STATUS_EXTERNAL_SUPPLIER_REDIRECT_DONE
            // );
            LoggerService.log(rparams.token, "", "Redirecting to supplier " + resp.redirectUrl, JSON.stringify(resp));
          } else if (resp.redirectType === 4) {
            await updateStatus(
              rObj.token,
              rObj.tokenId,
              StatusConstants.STATUS_INTERNAL_CLIENT_REDIRECT_START,
              StatusConstants.SUB_STATUS_EXTERNAL_REDIRECT_DONE
            );
            LoggerService.log(
              rparams.token,
              "",
              "Redirecting to client survey " + resp.redirectUrl,
              JSON.stringify(resp)
            );
          }
          window.location.href = `${resp.redirectUrl}`;
        }
      })
      .catch((err) => {
        console.error(err);
        setLoader(false);
        navigate(`/error`);
      });
  };

  let postAnswer = async (event) => {
    event.preventDefault();
    if (errorIndex === "") {
      let reqObj = {};
      let params = JSON.parse(sessionStorage.getItem("params"));
      reqObj.traceId = params.traceId;
      reqObj.surveyId = params.surveyId;
      reqObj.userId = params.userId;
      reqObj.token = params.token;
      reqObj.tokenId = params.tokenId;
      reqObj.tokenId = params.tokenId;
      reqObj.responses = [
        {
          questionId: obj.id,
          questionCategoryId: obj.questionCategoryId,
          answerText: obj.answerText,
          answerId: obj.answerId,
          copyText: copyPasteData,
          pageLoadTime: pageLoadTime,
          textChangeTime: textChangeTime,
          subimtTime: subimtTime,
        },
      ];
      setLoader(true);
      LoggerService.log(
        params.token,
        "",
        "Saving survey response for question " + obj.id + " with answer id" + obj.answerId,
        JSON.stringify(reqObj)
      );
      RespondentFlowApi.post("/screener/v1/response", reqObj)
        .then(async (resp) => {
          LoggerService.log(params.token, "", "Saving survey response result received ", JSON.stringify(resp));
          setLoader(false);
          setObj({});
          if (resp.result === "not_ok") {
            navigate(`/error?token=${params.token}`);
            return;
          }
          if (resp.status === 13) {
            LoggerService.log(params.token, "", "Demographic terminate status received ", JSON.stringify(resp));
            await handleStatusUpdate(resp);
          } else if (data.length === idx + 1) {
            let rObj = {
              traceId: params.traceId,
              token: params.token,
              tokenId: params.tokenId,
              status: resp.status,
              surveyUid: params.surveyUid,
              vendorUid: params.vendorUid,
            };
            LoggerService.log(params.token, "", "Checking status on screener end", rObj);
            params["status"] = resp.status;

            setLoader(true);
            RespondentFlowApi.post("/surveyLanding/v1/update/status", params)
              .then(async (resp) => {
                if (resp.result === "not_ok") {
                  navigate(`/error?token=${params.token}`);
                  return;
                }
                if (resp.isInternalRedirect) {
                  LoggerService.log(params.token, "", "New Survey received " + resp.surveyUid, JSON.stringify(resp));
                  setLoader(false);
                  let obj = {};
                  obj.traceId = resp.traceId;
                  obj.paramList = resp.paramList;
                  obj.sessionUid = resp.sessionUid;
                  obj.surveyUid = resp.surveyUid;
                  obj.vendorUid = resp.vendorUid;
                  obj.token = resp.token;
                  obj.tokenId = resp.tokenId;
                  obj.userUid = resp.userUid;
                  obj.userId = resp.userId;
                  sessionStorage.removeItem("params");
                  sessionStorage.setItem("params", JSON.stringify(obj));

                  window.location.href = `/screener?token=${resp.token}`;
                } else {
                  sessionStorage.removeItem("params");
                  if (resp.redirectType === 3) {
                    // await updateStatus(
                    //   rObj.token,
                    //   StatusConstants.STATUS_SUPPLIER_REDIRECT_START,
                    //   StatusConstants.SUB_STATUS_EXTERNAL_REDIRECT_DONE
                    // );
                    LoggerService.log(
                      params.token,
                      "",
                      "Redirecting to supplier " + resp.redirectUrl,
                      JSON.stringify(resp)
                    );
                  } else if (resp.redirectType === 4) {
                    await updateStatus(
                      rObj.token,
                      rObj.tokenId,
                      StatusConstants.STATUS_INTERNAL_CLIENT_REDIRECT_START,
                      StatusConstants.SUB_STATUS_EXTERNAL_REDIRECT_DONE
                    );
                    LoggerService.log(
                      params.token,
                      "",
                      "Redirecting to client survey " + resp.redirectUrl,
                      JSON.stringify(resp)
                    );
                  }
                  window.location.href = `${resp.redirectUrl}`;
                }
              })
              .catch((err) => {
                setLoader(false);
                console.error(err);
                navigate(`/error`);
              });
          } else {
            setIdx(idx + 1);
          }
        })
        .catch((e) => {
          setLoader(false);
          console.error(e);
          navigate(`/error`);
        });
    }
  };
  useEffect(() => {
    if (data.length > 0 && data[idx]) {
      setObj(JSON.parse(JSON.stringify(data[idx])));
    }

    // eslint-disable-next-line
  }, [data, idx]);

  useEffect(() => {
    if (
      obj?.answerId?.length === 0 ||
      obj?.answerText === null ||
      obj?.answerText === "" ||
      (obj.id === 59 && obj?.answerText > 99) ||
      (obj.id === 59 && obj?.answerText < 0)
    ) {
      setErrorIndex(obj.id);
    } else {
      setErrorIndex("");
    }
    // eslint-disable-next-line
  }, [obj]);

  const responseData = (e) => {
    e.preventDefault();
  };

  /** Copy paste */
  const textElementRef = useRef(null);
  const pastedTextElementRef = useRef(null);
  const pageLoadTextTimeElementRef = useRef(null);
  const entryStartTextTimeElementRef = useRef(null);
  const submitTextTimeElementRef = useRef(null);
  const submitButtonElementRef = useRef(null);

  var pageLoadListener = function (time, error) {
    setPageLoadTime(encodeToBase64(time));
    // setCopyPasteData(payload);
  };
  var copyPasteListener = function (pastedText, error) {
    setCopyPasteData(encodeToBase64(pastedText));
  };
  var textEventListener = function (answerTime, error) {
    setTextChangeTime(encodeToBase64(answerTime));
  };
  var submitEventListener = function (submitTime, error) {
    setSubmitTime(encodeToBase64(submitTime));
  };

  useEffect(() => {
    if (data.length > 0 && data[idx] && data[idx] && data[idx].questionCategoryId === 4) {
      const objElement = {
        textElementId: textElementRef?.current?.id,
        pastedTextElementId: pastedTextElementRef?.current?.id,
        pageLoadTextTimeElementId: pageLoadTextTimeElementRef?.current?.id,
        entryStartTextTimeElementId: entryStartTextTimeElementRef?.current?.id,
        submitTextTimeElementId: submitTextTimeElementRef?.current?.id,
        submitButtonElementId: submitButtonElementRef?.current?.id,
      };

      const initializeScReviewLib = () => {
        try {
          if (window.scReviewLib) {
            new window.scReviewLib(
              environment.RD_OE_BASE, // Replace with actual server URL
              environment.RD_PUBLISHABLE_KEY, // Replace with actual publishable key
              objElement,
              pageLoadListener,
              copyPasteListener,
              textEventListener,
              submitEventListener
            );
          }
        } catch (e) {
          console.log(e);
        }
      };

      if (typeof scReviewLib !== "undefined") {
        initializeScReviewLib();
      } else {
        const script = document.createElement("script");
        script.src = "https://prod.rtymgt.com/static/js/review/sc_review_lib_secured_v1-2.min.js?v=1.0.1";
        script.onload = initializeScReviewLib;
        document.body.appendChild(script);
      }
    }
  }, [data, idx]); // Empty dependency array to run only once on mount

  return (
    <>
      {Loader ? (
        <>
          <div className="flex justify-center  h-screen bg-gray-200">
            <div>
              <BounceLoader
                color="rgb(129, 176, 60)"
                loading={true}
                size={60}
                textalign="center"
                aria-label="Loading Spinner"
                data-testid="loader"
              />
              <span
                style={{
                  fontWeight: "700",
                  marginTop: "20px",
                  display: "block",
                  textalign: "center",
                  color: "rgb(129, 176, 60)",
                }}
              >
                {"Loading..."}
              </span>
            </div>
          </div>
        </>
      ) : (
        <div className="questionCard ">
          <form onSubmit={Loader ? responseData : postAnswer} noValidate className="h-[100%] relative">
            <div className="question-body ">
              <h1 className="question-text">{data[idx]?.text}</h1>
              <div className="mb-10">
                {(data.length > 0 && data[idx] && data[idx] && data[idx].type === 4) ||
                (data.length > 0 && data[idx] && data[idx] && data[idx].type === 3) ? (
                  // change here
                  data.length > 0 && data[idx] && data[idx] && data[idx].questionCategoryId === 4 ? (
                    <>
                      <input
                        id="[encoded_pasted_text_element_id]"
                        ref={pastedTextElementRef}
                        type="text"
                        style={{ display: "none" }}
                      />
                      <input
                        id="[encoded_page_load_time_text_element_id]"
                        ref={pageLoadTextTimeElementRef}
                        type="text"
                        style={{ display: "none" }}
                      />
                      <input
                        id="[encoded_typed_time_text_element_id]"
                        ref={entryStartTextTimeElementRef}
                        type="text"
                        style={{ display: "none" }}
                      />
                      <input
                        id="[encoded_submit_time_text_element_id]"
                        ref={submitTextTimeElementRef}
                        type="text"
                        style={{ display: "none" }}
                      />
                      <textarea
                        id="oe_text_area"
                        ref={textElementRef}
                        className="mt-2 font-medium text-sm border-2 border-black"
                        // value={obj?.answerText}
                        rows={5}
                        onChangeCapture={(e) => {
                          let payload = { ...obj };
                          payload.answerText = e.target.value;
                          setObj(JSON.parse(JSON.stringify(payload)));
                        }}
                        // error={!match && errorIndex === data[idx]?.id ? true : false}
                      />
                    </>
                  ) : (
                    <>
                      <TextField
                        size="small"
                        variant="outlined"
                        type="text"
                        className="mt-2 font-medium text-sm border-2 border-black"
                        value={obj?.answerText}
                        onChange={(e) => {
                          let payload = { ...obj };
                          payload.answerText = e.target.value;
                          setObj(JSON.parse(JSON.stringify(payload)));
                        }}
                        error={!match && errorIndex === data[idx]?.id ? true : false}
                      />

                      {!match && errorIndex === data[idx]?.id && (
                        <div
                          style={{
                            color: "red",
                            marginTop: "5px",
                            fontSize: "13px",
                          }}
                        >
                          {obj.answerText > 99
                            ? "This value not be greater then 100 and"
                            : obj.answerText < 0
                            ? "This value be greater then 0"
                            : "This field is required!"}
                        </div>
                      )}
                    </>
                  )
                ) : data.length > 0 && data[idx] && data[idx].type === 1 ? (
                  <>
                    {!match && errorIndex === data[idx]?.id && (
                      <div
                        style={{
                          color: "red",
                          marginTop: "5px",
                          fontSize: "13px",
                        }}
                      >
                        This field is required!
                      </div>
                    )}
                    <div
                      className={
                        data[idx]?.options.length > 12
                          ? "grid md:grid-cols-2 gap-4 grid-cols-1 mt-2"
                          : "grid md:grid-cols-1 gap-4 grid-cols-1 mt-2"
                      }
                    >
                      {data.length > 0 &&
                        data[idx].options.map((item, i) => {
                          return (
                            <div className="flex  space-x-3 " key={i}>
                              <input
                                name={obj?.id}
                                type="radio"
                                id={`radio_${item.id}`}
                                checked={obj?.answerId?.includes(item.id)}
                                onChange={(e, value) => {
                                  let payload = { ...obj };
                                  if (e.target.checked) {
                                    payload.answerId = [item.id];
                                  } else {
                                  }
                                  setObj(JSON.parse(JSON.stringify(payload)));
                                }}
                              />
                              <label className="text-sm font-medium cursor-pointer" htmlFor={`radio_${item.id}`}>
                                {item.text}
                              </label>
                            </div>
                          );
                        })}
                    </div>
                  </>
                ) : (
                  <>
                    {!match && errorIndex === data[idx]?.id && (
                      <div
                        style={{
                          color: "red",
                          marginTop: "5px",
                          fontSize: "13px",
                        }}
                      >
                        This field is required!
                      </div>
                    )}

                    <div
                      className={
                        data[idx]?.options.length > 12
                          ? "grid md:grid-cols-2 gap-4 grid-cols-1 mt-2"
                          : "grid md:grid-cols-1 gap-4 grid-cols-1 mt-2"
                      }
                    >
                      {data.length > 0 &&
                        data[idx] &&
                        data[idx].options.map((item, id) => {
                          return (
                            <div className="flex  space-x-3" key={id}>
                              <input
                                id={`checkbox_${item.id}`}
                                name={obj?.id}
                                type="checkbox"
                                checked={obj?.answerId?.includes(item.id)}
                                onChange={(e, value) => {
                                  let payload = { ...obj };
                                  if (!e.target.checked) {
                                    payload.answerId = payload.answerId.filter((answer) => answer !== item.id);
                                  } else {
                                    payload.answerId.push(item.id);
                                  }
                                  setObj(JSON.parse(JSON.stringify(payload)));
                                }}
                              />
                              <label className="font-medium text-sm cursor-pointer" htmlFor={`checkbox_${item.id}`}>
                                {item.text}
                              </label>
                            </div>
                          );
                        })}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="flex flex-row-reverse w-full md:w-[100%] px-8 gap-5 h-fit	pb-4 bg-white absolute bottom-0">
              <button
                className="button"
                onClick={() => {
                  setMatch(false);
                }}
                id="[submit_button_id]"
                ref={submitButtonElementRef}
              >
                Next
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default QuesCard;
